<template>
  <div class="main-view">
    <div class="section filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="small">
        <el-form-item prop="title">
          <el-input v-model.trim="table.params.title" placeholder="产品名称">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="score_level">
          <el-select v-model="table.params.score_level" placeholder="综合评分" @change="onSearch">
            <el-option label="0-1分" :value="1"></el-option>
            <el-option label="1-2分" :value="2"></el-option>
            <el-option label="2-3分" :value="3"></el-option>
            <el-option label="3-4分" :value="4"></el-option>
            <el-option label="4-5分" :value="5"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:title="{row}">
        <div class="flex" style="align-items:center;">
          <el-image
            style="width: 80px; height: 40px;border-radius:2px;margin-right:10px"
            :src="row.image"
            fit="cover"
            :preview-src-list="[row.image]"></el-image>
            <div class="table-col-text">{{row.title}}</div>
        </div>
      </template>
      <template v-slot:amount="{row}">
        <span>{{row.amount}}元/人</span>
      </template>
      <template v-slot:score="{row}">
        <span style="color:#FD4100;font-weight: bold;">{{row.score}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click='showScore(row)'>查看评价</el-button>
      </template>
    </VTable>

    <div class="my-drawer shadow">
      <el-drawer
        style="position: absolute"
        title=""
        :visible.sync="drawerTable"
        direction="rtl"
        size="700px"
        :modal="false"
        :append-to-body="false">
        <template #title>
          <div class="el-dialog-title">产品评价记录({{appraise.total}}条)</div>
        </template>
        <el-alert
          title="设置“展示风采”将会把该条评价公开在用户端【风采专栏】，对回购产品和机构好评度均有帮助哦~"
          type="warning"
          show-icon
          :closable="false">
        </el-alert>
        
        <el-table :data="appraise.data" tooltip-effect="dark myTooltipsDrawer">
          <el-table-column property="appraise_name" label="评价人" width="100"></el-table-column>
          <el-table-column property="school_name" label="所属学校" width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column property="content" label="评价内容" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column property="score" label="评分" width="60">
            <template v-slot="{row}">
              <span style="color:#FD4100;font-weight: bold;">{{row.score}}</span>
            </template>
          </el-table-column>
          <el-table-column property="create_time" label="评分时间" width="160"></el-table-column>
          <el-table-column
            label="操作"
            width="140">
            <template v-slot="{row}">
              <el-button type="text" size="small" @click="showDetail(row)">详情</el-button>
              <span class="action-btn-line">|</span>
              <el-button type="text" :class="row.show_status == 1 ? 'colorDel' : ''" size="small" @click="handleOperate(row)">{{row.show_status == 1 ? '取消展示' : '展示风采'}}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-drawer>
    </div>

    <detail ref="detail"></detail>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'AccountList',
  components: {
    VTable,
    Detail,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "title", label: "图文标题", width:'300', hidden: false },
        { name: "amount", label: "售价", hidden: false },
        { name: "sales", label: "已售(份)", hidden: false},
        { name: "appraise_count", label: "评价次数", hidden: false},
        { name: "last_appraise_score", label: "最新评分", hidden: false},
        { name: "last_appraise_time", label: "最近评价时间", hidden: false},
        { name: "score", label: "综合评分", hidden: false},
        { name: "action", label: "操作", width: "110", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          title: '',
          score_level: '',
          has_appraise: 1, //是否有评论：0-无；1-有；
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      drawerTable: false,
      appraise: {
        params: {
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      good_id: '',
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询列表
    getTable() {
      this.$http.get('/company/goods/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 查看评价
    showScore(row) {
      this.drawerTable = true;
      this.good_id = row.id;
      this.getAppraise(row);
    },
    // 查询评价列表
    getAppraise(row) {
      let _params = {
        page: this.appraise.params.page,
        count: this.appraise.params.count,
        good_id: row.id,
      }
      this.$http.get('/company/appraise/list',{params:_params}).then(res => {
        this.appraise.data = res.data.list;
        this.appraise.total = res.data.total;
      })
    },
    // 评价详情
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 展示风采，取消展示
    handleOperate(row) {
      let str = row.show_status == 1 ? '取消展示' : '展示风采'
      let _type = row.show_status == 1 ? 2 : 1; // 1-展示风采；2-取消展示；
      this.$confirm(`请确认${str}操作？`, "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/company/appraise/operate', {id: row.id, show_status: _type}).then(res => { 
          if(res.code === 1) {
            this.$message.success('操作成功！');
            this.getAppraise({id:this.good_id});
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {});
    }
  }
}
</script>

<style scoped lang="scss">
.my-drawer {
  &::v-deep {
    .el-overlay {
      position: absolute;
    }
    .el-drawer__header {
      margin: 0;
      padding: 10px 20px;
    }
    
  }
}
.el-dialog-title {
  font-weight: bold;
}
.myTooltipsDrawer {
  max-width: 700px;
}
</style>